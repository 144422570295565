import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import { Route, Routes } from "react-router-dom";
import AdminQueenComponent from "./page/OtwQueenComponents/AdminQueenComponent";
import QueenAuth from "./page/OtwQueen/OtwQueenAuthPages/QueenAuth";
import QueenAccount from "./page/OtwQueen/QueenAccount";
import AdminQueenHeader from "./page/Header/AdminQueenHeader";
import PrivateRoute from "./page/PrivateRoute/PrivateRoute";
import Footer from "./page/Footer/Footer";

function App() {
  return (
    <div className="layout">
      <AdminQueenHeader />
      <Routes>
        <Route path="/" element={<AdminQueenComponent />}></Route>
        <Route path="/join/*" element={<QueenAuth />} />
        <Route
          path="/account/*"
          element={
            <PrivateRoute>
              <QueenAccount />
            </PrivateRoute>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
