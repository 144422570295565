import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillMinusCircle,
} from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";
import ProfileEditModal from "../OtwQueenComponents/ProfileEditModal";
import ChangePasswordModal from "../OtwQueenComponents/ChangePasswordModal";
import UploadNidModal from "../OtwQueenComponents/UploadNidModal";
import { useQueenAuth } from "../../Context/QueenAuthContext";
import { url } from "../../Helpers/Constant";

const QueenProfile = () => {
  const { queenAuth, setQueenAuth } = useQueenAuth();
  const [image, setImage] = useState(null);

  // Edit Profile Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Change Password Modal
  const [showCPModal, setShowCPModal] = useState(false);
  const handleCPClose = () => setShowCPModal(false);
  const handleCPShow = () => setShowCPModal(true);

  // Upload NID Modal
  const [showNidModal, setShowNidModal] = useState(false);
  const handleCloseNidModal = () => setShowNidModal(false);
  const handleShowNidModal = () => setShowNidModal(true);

  const {
    name,
    email,
    phone,
    address,
    post_code,
    photo,
    id,
    city,
    nid_front,
    nid_back,
    status,
  } = queenAuth;

  const handleUpdateImage = (image) => {
    const formData = new FormData();
    formData.append("photo", image);
    fetch(`${url}/andro/api/admin/queen/update/dp/${id}`, {
      method: "PUT",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert("Profile photo updated successfully");
          queenAuth["photo"] = data.filename;
          setQueenAuth(queenAuth);
          setImage(image);
        } else {
          alert("Can't change profile photo now, try again later");
        }
      });
  };

  return (
    <div>
      <div className="text-center position-relative">
        {image ? (
          <img
            className="rounded-circle"
            src={URL.createObjectURL(image)}
            width={200}
            height={200}
            alt=""
          />
        ) : (
          <img
            className="rounded-circle"
            src={`${url}/api/get/image/queens/${photo}`}
            width={200}
            height={200}
            alt=""
          />
        )}
        <div className="position-absolute top-0 py-3  text-success ">
          <label htmlFor="image" className="pointer">
            <span className="fw-bold">Update</span>
            <BiImageAdd className="fs-2" />
            <input
              className="d-none"
              type="file"
              id="image"
              accept=".jpg, .png, .jpeg"
              onChange={(e) => handleUpdateImage(e.target.files[0])}
            />
          </label>
        </div>
        <div className="position-absolute top-0 end-0 py-3">
          {status === "Approved" && (
            <>
              <AiFillCheckCircle className="fs-4 text-success" />
              <small className="fw-bold text-success">(Approved)</small>
            </>
          )}
          {status === "Pending" && (
            <>
              <AiFillClockCircle className="fs-4 text-warning" />
              <small className="fw-bold text-warning">(Pending)</small>
            </>
          )}
          {status === "Rejected" && (
            <>
              <AiFillMinusCircle className="fs-4 text-danger" />
              <small className="fw-bold text-danger">(Rejected)</small>
            </>
          )}
        </div>
      </div>
      <div>
        <div className="text-center">
          <p className="fs-4 fw-bold">Profile Info</p>
        </div>
        <hr className="my-1" />
        <ul className="me-4">
          <li>
            <span className="fw-bold">Name: </span> {name}
          </li>
          <hr className="mb-2 mt-0" />
          <li>
            <span className="fw-bold">Phone: </span>
            {`0${phone}`}
          </li>
          <hr className="mb-2 mt-0" />
          <li>
            <span className="fw-bold">Queen ID: </span> {id}
          </li>
          <hr className="mb-2 mt-0" />
          <li>
            <span className="fw-bold">Email: </span>
            {email}
          </li>
          <hr className="mb-2 mt-0" />
          <li>
            <span className="fw-bold">City: </span>
            {city}
          </li>
          <hr className="mb-2 mt-0" />
          <li>
            <span className="fw-bold">Address: </span>
            {address}
          </li>
          <hr className="mb-2 mt-0" />
          <li>
            <span className="fw-bold">City-Postcode: </span>
            {post_code}
          </li>
          <hr className="mb-2 mt-0" />
        </ul>
        <div className="d-flex  justify-content-between mx-4">
          <Button onClick={handleShow} variant="outline-primary">
            Edit Profile
          </Button>
          <Button onClick={handleCPShow} variant="outline-primary">
            Change Password
          </Button>
          <div>
            {nid_front ? (
              <Button onClick={handleShowNidModal} variant="outline-primary">
                Edit NID
              </Button>
            ) : (
              <Button onClick={handleShowNidModal} variant="outline-primary">
                Upload NID
              </Button>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="mt-4">
          {nid_front ? (
            <p className="fs-4 fw-bold ">NID Information</p>
          ) : (
            <p className="text-warning fs-4  text-center">
              Upload Your NID For Verify Yourself
            </p>
          )}

          {/* <AiOutlineIdcard className='fs-2 text-success' /> */}
        </div>
        <div>
          {nid_front && (
            <div>
              <div className="d-flex">
                <div>
                  <img
                    className="w-100 p-2"
                    height={150}
                    src={`${url}/api/get/image/nids/${nid_front}`}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="w-100 p-2"
                    height={150}
                    src={`${url}/api/get/image/nids/${nid_back}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ChangePasswordModal show={showCPModal} handleClose={handleCPClose} />
      <ProfileEditModal show={show} handleClose={handleClose} />
      <UploadNidModal
        show={showNidModal}
        handleClose={handleCloseNidModal}
        id={id}
      />
    </div>
  );
};

export default QueenProfile;
