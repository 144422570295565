import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useQueenAuth } from "../../Context/QueenAuthContext";
import { url } from "../../Helpers/Constant";

const BecameFreelancer = () => {
  const { queenAuth } = useQueenAuth();
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [seller, setSeller] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    data.id = queenAuth.id;
    data.name = queenAuth.name;
    data.photo = queenAuth.photo;
    data.city = queenAuth.city;
    data.email = queenAuth.email;

    fetch(`${url}/out/api/seller/apply/${queenAuth.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSeller(true);
          setMessage(data.message);
          time();
        } else {
          setError("Already Apply for seller");
          setIsLoading(false);
        }
      });
  };
  const time = () => {
    setTimeout(() => {
      navigate("/admin-queen/account");
    }, 5000);
  };
  useEffect(() => {
    document.title = "Freelancer | Charima";
  }, []);

  return (
    <div className="mt-3 mb-5">
      {!seller && (
        <div className="my-4 text-center">
          <h2>Apply to became a Freelancer</h2>
        </div>
      )}
      {seller ? (
        <div>
          <h2 className="text-center text-success bold mt-5">{message}</h2>
          <div className="mt-5 text-center">
            <Link to="/admin-queen/account">
              <Button variant="success next-btn">Back to Queen</Button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <form onSubmit={handleSubmit(onSubmit)} className="fund">
            <div className="d-flex mb-3">
              <h4>Select Payment Method</h4>
              <select {...register("bank_name")} className="mx-3">
                <option value="bkash">Bkash</option>
                <option value="nagad">Nagad</option>
                <option value="upay">Upay</option>
              </select>
            </div>
            <input
              type="number"
              required
              className="sign-up-input"
              {...register("account_number")}
              placeholder="Account Number"
            />

            <p className="my-4 text-danger text-center"> {error} </p>
            {isLoading ? (
              <input
                className="sign-up-input"
                type="submit"
                value="Please wait.."
                disabled
              />
            ) : (
              <input className="sign-up-input" type="submit" value="Submit" />
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default BecameFreelancer;
