import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQueenAuth } from "../../../../Context/QueenAuthContext";
import { url } from "../../../../Helpers/Constant";
import "../../../Common.css";

const Login = ({ joinPhone }) => {
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState(joinPhone);
  const [error, setError] = useState("");
  const [showPass, setShowPass] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const { setQueenAuth } = useQueenAuth();

  const navigate = useNavigate();

  const handleQueenLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    if (password.length >= 6) {
      fetch(`${url}/andro/api/auth/queen/login`, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ phone, password }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setQueenAuth(data.data);
            setIsLoading(false);
            navigate("/account");
          } else {
            setIsLoading(false);
            setError("Wrong Phone or Password");
            setTimeout(() => {
              setError("");
            }, 4000);
          }
        });
    } else {
      setIsLoading(false);
      setError("Wrong Password");
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };
  return (
    <div className="pb-3 my-5">
      <h3 className="fw-bold text-center fs-3"> Login</h3>
      <form action="" onSubmit={handleQueenLogin}>
        <div className="mt-3">
          <div className="my-2">
            <label className="fw-bold">Enter your phone number</label>
            <input
              placeholder="01XXXXXXXXX"
              type="text"
              className="sign-up-input w-100"
              defaultValue={joinPhone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="my-2">
            <label className="fw-bold">Enter your password</label>
            <input
              placeholder="Your Password"
              type={showPass}
              className="sign-up-input w-100"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="mt-3 d-flex justify-content-between">
              <div>
                <input
                  type="checkbox"
                  onClick={(e) => {
                    if (e.target.checked) {
                      setShowPass("text");
                    } else {
                      setShowPass("password");
                    }
                  }}
                  id=""
                />{" "}
                <span>Show password</span>
              </div>
              <div>
                <Link className="text-decoration-none" to="/join/register">
                  {" "}
                  <p>No account yet?</p>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <p className="text-center text-danger py-1">{error}</p>
          </div>
          <div className="mb-2 text-center">
            <Link className=" text-decoration-none" to="/join/forget-password">
              Forgot password?
            </Link>
          </div>
          {isLoading ? (
            <input
              type="submit"
              className="next-btn w-100"
              value="Logging in..."
              disabled
            />
          ) : (
            <input type="submit" className="next-btn w-100" value="Login" />
          )}
        </div>
      </form>
    </div>
  );
};

export default Login;
