import React, { useState } from "react";
import { useQueenAuth } from "../../Context/QueenAuthContext";
import { url } from "../../Helpers/Constant";

const ContactUs = () => {
  const { queenAuth } = useQueenAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handelSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = await fetch(`${url}/api/client/send/contact/msg`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        name: queenAuth.name,
        email: queenAuth.email,
        phone: queenAuth.phone,
        message: message,
      }),
    });
    const data = await res.json();
    if (data.success) {
      alert(data.message);
      setMessage("");
      setLoading(false);
    } else {
      setErrorMessage(data.message);
      setLoading(false);
    }
  };

  return (
    <div className="my-5 py-5">
      <div>
        <h3 className="fw-bold text-center fs-3"> Send Message</h3>
      </div>
      {/* <div className='text-center'>
        <img width={300} src='/assets/admin-queen/queen.png' alt='' />
      </div> */}
      <form onSubmit={handelSubmit}>
        <div className="mt-3">
          <div className="my-2">
            <input
              placeholder="demo@gmail.com"
              type="text"
              disabled
              value={queenAuth.email}
              className="sign-up-input w-100"
            />
          </div>
          <div className="my-2">
            <textarea
              placeholder="Write your message"
              multiple
              rows={5}
              value={message}
              type="text"
              className="sign-up-input w-100"
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>

          <div>
            <p className="text-center text-danger py-1">{errorMessage}</p>
          </div>

          <div className="text-center mt-3">
            {loading ? (
              <input
                type="submit"
                className="next-btn w-100"
                value="Loading..."
                disabled
              />
            ) : (
              <input
                type="submit"
                className="next-btn w-75"
                value="Send message"
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
