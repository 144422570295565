import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../page/AdminQueen.css";
import { Link } from "react-router-dom";
import { url } from "../Helpers/Constant";

const GuaranterNidFrom = ({ gId }) => {
  const { handleSubmit } = useForm();
  const [nidFront, setNidFront] = useState("");
  const [nidBack, setNidBack] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("nid_front", nidFront);
    formData.append("nid_back", nidBack);

    // put data to server
    fetch(`${url}/api/fund/update/g/nid/${gId}`, {
      method: "PUT",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setSuccess(true);
          setIsLoading(false);
        } else {
          setError(data.message);
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {
    document.title = "Guaranter Nid From | Charima";
  }, []);
  return (
    <div className="my-5">
      <div className="from-dev">
        {!success && <h3 className="my-4 text-center">Guaranter Nid Card </h3>}
        {success ? (
          <div className="text-center text-success mt-3">
            <h3>We Recived Your Request</h3>
            <Link to="/admin-queen/account">
              <button className="next-btn mt-3">Back to Queen</button>
            </Link>
          </div>
        ) : (
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="fund">
              <label className="mt-2">Nid Front</label>
              <input
                className="sign-up-input"
                required
                onChange={(e) => setNidFront(e.target.files[0])}
                type="file"
              />
              <label className="mt-2">Nid Back</label>
              <input
                className="sign-up-input mb-3"
                required
                onChange={(e) => setNidBack(e.target.files[0])}
                type="file"
              />
              <p className="text-danger text-center my-2">{error}</p>
              {isLoading ? (
                <input
                  type="submit"
                  className="sign-up-input "
                  value="Please wait..."
                  disabled
                />
              ) : (
                <input
                  className="sign-up-input mt-4"
                  type="submit"
                  value="Submit"
                />
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default GuaranterNidFrom;
