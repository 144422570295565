import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AdminQueenComponent = () => {
  return (
    <div className="pt-4">
      <div className="d-flex  align-items-center justify-content-center py-5 ">
        <div className="w-50 d-md-block d-none  ">
          <Container>
            <Row className="mt-5 ">
              <Col md={6}>
                <div className="mx-5 text-center download-queen-app-bg">
                  <p className="fs-5 fw-bold ">Download App</p>
                  <img
                    className="img-fluid"
                    src="/assets/play-store.png"
                    alt=""
                  />
                </div>
                {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                  href="https://play.google.com/store/apps/details?id=com.m360ict.ontheway_queen"
                >
                  <div className="mx-5 text-center download-queen-app-bg">
                    <p className="fs-5 fw-bold ">Download App</p>
                    <img
                      className="img-fluid"
                      src="/assets/play-store.png"
                      alt=""
                    />
                  </div>
                </a> */}
              </Col>
              <Col md={6}>
                <Link className="text-decoration-none" to="/join">
                  <div className="mx-5  text-center be-queen-from-otw-bg">
                    <p className="fs-5 fw-bold ">Use This Website</p>
                    <img
                      className="img-fluid be-queen-from-otw-icon"
                      src="/charima.jpg"
                      alt=""
                    />
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="text-end  ms-5">
          <img
            className="img-fluid"
            src="/assets/admin-queen/queen_image.png"
            alt=""
          />
        </div> */}
      </div>

      <div className="d-md-none d-block">
        <Container>
          <Row>
            <Col md={6}>
              <Link className="text-decoration-none" to="/account">
                <div className="mx-5 mt-5 text-center be-queen-from-otw-bg">
                  <p className="fs-3 fw-bold ">Use This Website</p>
                  <img
                    className="img-fluid be-queen-from-otw-icon"
                    src="/assets/main-logo.png"
                    alt=""
                  />
                </div>
              </Link>
            </Col>
            {/* <Col className="mt-4" md={6}>
              <a
                className="text-decoration-none"
                href="https://play.google.com/store/apps/details?id=com.m360ict.ontheway_queen"
              >
                <div className="mx-5 text-center download-queen-app-bg">
                  <p className="fs-3 fw-bold ">Download Queen App</p>
                  <img
                    className="img-fluid"
                    src="/assets/play-store.png"
                    alt=""
                  />
                </div>
              </a>
            </Col> */}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AdminQueenComponent;
