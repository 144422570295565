// export const url = "http://192.168.0.236:4001";
export const url = " https://server.charima.online";
// export const url = 'http://36.255.68.123:4000';
// export const url = "https://artistic-server.job360.world";

// products constant
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

// cart constats
export const SET_CART_SUCCESS = "SET_CART_SUCCESS";

// Trainee Constats
export const SET_SINGLE_VALUE = "SET_SINGLE_VALUE";

// Guest order constants
export const SET_GUEST_ORDER_DETAILS = "SET_GUEST_ORDER_DETAILS";
export const SET_GUEST_BY_PHONE = "SET_GUEST_BY_PHONE";

// Join try constants
export const JOIN_TRY_SUCCESS = "JOIN_TRY_SUCCESS";

// Signup Data constants
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";

// Authentication success constants
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
