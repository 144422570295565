import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "../page/AdminQueen.css";
import { useQueenAuth } from "../Context/QueenAuthContext";
import { url } from "../Helpers/Constant";

const FundFrom = ({ setFundId }) => {
  const { queenAuth } = useQueenAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [dob, setDob] = useState(null);
  const [error, setError] = useState("");
  const nevigate = useNavigate();

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");
    data.dob = dob;
    data.queen_id = queenAuth.id;
    if (data.nid_number.length > 18) {
      setError("Invalid NID");
    }

    // post from data to server
    fetch(`${url}/api/fund/apply`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setFundId(data.fund_id);
          setError("");
          setIsLoading(false);
          nevigate("/admin-queen/account/add-fund/guaranter");
        } else {
          setIsLoading(false);
          setError(data.message);
        }
      });
  };
  useEffect(() => {
    document.title = "Fund From | Charima";
  }, []);

  return (
    <div className="my-5 from-back-color">
      <div>
        <h2 className="my-3 text-center bold">Fund Deatils for Queen </h2>
        <div className="from-dev">
          <form onSubmit={handleSubmit(onSubmit)} className="fund">
            <input
              className="sign-up-input"
              type="number"
              {...register("nid_number")}
              required
              placeholder="Enter your nid-number"
            />
            <br />
            <label className="mt-3">Date of birth</label>
            <input
              className="sign-up-input "
              type="date"
              onChange={(e) => setDob(e.target.value)}
              required
              // {...register('dob', {
              //   valueAsDate: true,
              // })}
            />

            <div className="d-flex mt-2 align-items-center  ">
              <div>
                <select
                  className="sign-up-input"
                  required
                  {...register("guardian_type")}
                >
                  <option value="Father">Father-name</option>
                  <option value="Husband">Husband's-name</option>
                </select>
              </div>
              <div className="mx-3">
                <input
                  {...register("guardian_name")}
                  required
                  type="text"
                  className="sign-up-input"
                  placeholder="guardian-name"
                />
              </div>
            </div>
            <textarea
              style={{ height: "150px" }}
              className="sign-up-input w-100 mt-3"
              required
              minLength={100}
              {...register("why")}
              placeholder="why you need fund?"
            />
            <input
              className="sign-up-input "
              type="number"
              required
              min={1000}
              max={50000}
              {...register("amount")}
              placeholder="Amount 1000-50000 tk"
            />
            <div className="d-flex justify-content-between">
              <div className="mt-3">
                <label> Return Time</label>
                <div>
                  <select
                    required
                    className="sign-up-input "
                    {...register("return_time")}
                  >
                    <option value="1">0-1 m</option>
                    <option value="2">1-2 m</option>
                    <option value="3">2-3 m</option>
                    <option value="4">3-4 m</option>
                    <option value="5">4-5 m</option>
                    <option value="6">5-6 m</option>
                  </select>
                </div>
              </div>
              <div className="my-3">
                <label> Return type </label>
                <div>
                  <select
                    className="sign-up-input "
                    required
                    {...register("return_type")}
                  >
                    <option value="product">Product</option>
                    <option value="fund">Fund</option>
                  </select>
                </div>
              </div>
            </div>

            <p className="text-danger text-center my-2">{error}</p>
            {isLoading ? (
              <input
                type="submit"
                className="sign-up-input "
                value="Please wait..."
                disabled
              />
            ) : (
              <input className="sign-up-input " type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default FundFrom;
